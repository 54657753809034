import { Box, Flex, Grid, Text } from '@chakra-ui/react'
import { BsTelephoneForward } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import logo from 'Assets/ZIBPL/zopper_logo.svg'
import { HEADER_HEIGHT } from 'Constants/global.constants'
import { POSP_URL } from 'Constants'

export const Header = () => {
  return (
    <header className='w-[100%]'>
      <Box
        bg='white'
        h={`${HEADER_HEIGHT}`}
        paddingTop='4'
        w='full'
        boxShadow='0px 3px 6px #00000029'
      >
        <Grid justify-content='space-between'>
          <Box
            gridColumn='2'
            className='lg:w-[140px] lg:h-[50px] xs:w-[100px] xs:h-[25px] md:w-[140px] md:h-[50px] sm:w-[140px] sm:h-[50px]'
          >
            <Link to='/'>
              <img src={logo} alt='bimaboss' />
            </Link>
          </Box>
          <Flex
            className='space-x-9 lg:text-base md:text-base xs:text-xs'
            gridColumnStart='14'
            marginY='auto'
          >
            <Text
              color='secondary'
              cursor='pointer'
              onClick={() => {
                window.open(POSP_URL, '_self')
              }}
            >
              Become a POSP
            </Text>{' '}
            {/* <a href={'#aboutus'} > */}
            <Text color='secondary' cursor='pointer' onClick={() => window.scrollTo(0, 100)}>
              {' '}
              About Us
            </Text>
            {/* </a> */}
            {/* <a href={'#contactus'}> */}
            <Text
              color='secondary'
              cursor='pointer'
              onClick={() => window.scrollTo(0, document.body.scrollHeight)}
            >
              Contact Us
            </Text>
            {/* <Text color='secondary' cursor='pointer' onClick={() => window.open('https://insurance-uat.zopperinsurance.com/login', '_self')}>Login</Text> */}
          </Flex>
        </Grid>
      </Box>
    </header>
  )
}
